html {
  height: 100%;
}
body {
  min-height: 100%;
}

header {
  height: 10vh
}

.client-logo {
  width: 100px;
  margin-left: 1vw;
  padding-top: 20px;
}

.fastloop-logo {
  width: 100px;
  margin-left: 1vw;
  /* margin-left: 10px; */
}

.user-icon {
  width: 30;
  padding-left: 5px;
}

.user-link {
  margin-right: 2vw;
  display: flex;
}

.navbar {
  box-shadow: none !important;
  background-color: #f7f7f7;
}

.sidebar {
  position: fixed;
  height: 100%;
  box-shadow: none !important;
  background-color: #f7f7f7;
}

.sidebar-bottom {
  position: fixed;
  bottom: 80px;
}

/* .powered-by-div {
  position: relative;
  height: 50%;
} */

.powered-by-div .powered-by-span {
  position: fixed;
  bottom: 0;
}

.location-group {
  display: inline;
  margin-left: 20%;
}

img.map-location {
  width: 150px;
}

img.active {
  background-color: #eb4d27;
}

a.map-location {
  cursor: pointer;
}

.position-sticky {
  margin-top: 40px;
}

.side-link {
  /* margin-left: 15px; */
}

#sidebarMenu .nav-item .nav-link {
  padding-left: 5px;
  padding-right: 0;
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

.b-example-divider {
  height: 3rem;
  background-color: rgba(0, 0, 0, .1);
  border: solid rgba(0, 0, 0, .15);
  border-width: 1px 0;
  /* box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15); */
}

.b-example-vr {
  flex-shrink: 0;
  width: 1.5rem;
  height: 100vh;
}

.bi {
  vertical-align: -.125em;
  fill: currentColor;
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

#sidebarMenu .row .col {
  padding-left: 0;
}

.col-md-9.ms-sm-auto.col-lg-10.px-md-4.no-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.col.no-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.sub-nav-item {
  margin-left: 15px;
  font-size: 14px;
}

.sub-nav-item .nav-link {
  padding-top: 3px;
  padding-bottom: 3px;
}

.more-false {
  display: none;
}

.more-true {
  display: block;
}

.more-plus-false {
  display: none;
}

.more-false-true {
  display: block;
}

.chevron-down {
  transform: rotate(180deg);
}
